import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import DiscountGrossPresenter from "./DiscountGrossPresenter";
import BaseListPage from "../../../base/BaseListPage";
import { findObjectUseCase } from "../../../usecases/object";
import withRouter from "../../../withRouter";
import Export from "../../../components/Export";
import printChart from "../../../PrintChart";
import { Progress } from "nq-component";

class DiscountGrossPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new DiscountGrossPresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      selectedStoreCode: "",
      selectedMonth: "",
      selectedYear: "",
      store: [],
      storess: "",
      range: "",
      selectedMonth: "",
      selectedMonthName: "",
    };
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleDropdownChange(event) {
    const monthNames = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };
    let monthName;
    this.setState({ selectedMonth: event.target.value });

    if (event.target.value === "AllMonths") {
      this.presenter.onChangeDate("AllMonths");
    } else {
      this.setState({ range: event.target.value });
      this.presenter.onChangeDate(JSON.parse(event.target.value));
      const monthi = JSON.parse(event.target.value).$gte.split("-");
      monthName = Object.keys(monthNames).find(
        (key) => monthNames[key] === monthi[1]
      );
    }

    this.setState({ selectedMonthName: monthName });
  }

  getMonthOptions() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.map((month) => (
      <option key={month} value={month}>
        {month}
      </option>
    ));
  }

  months() {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthsFormatted = monthNames.map((name, index) => {
      const monthNumber = (index + 1).toString().padStart(2, "0");
      const firstDayOfMonth = `${this.state.selectedYear}-${monthNumber}-01T05:00:00.000Z`;

      // Calculate the last day of the month
      const lastDayOfMonthDate = new Date(
        this.state.selectedYear,
        index + 1,
        0
      ); // The 0 here gets the last day of the previous month, i.e., the month we're interested in
      // const lastDayOfMonth = `${lastDayOfMonthDate.getFullYear()}-${(lastDayOfMonthDate.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonthDate.getDate().toString().padStart(2, '0')}T23:59:59.999Z`;
      const lastDayOfMonth = `${this.state.selectedYear}-${(
        lastDayOfMonthDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${lastDayOfMonthDate
        .getDate()
        .toString()
        .padStart(2, "0")}T04:59:59.999Z`;

      return {
        name, // The display name of the month
        createdAt: {
          $gte: firstDayOfMonth,
          $lte: lastDayOfMonth,
        },
      };
    });

    return monthsFormatted;
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  handleYearChange = (event) => {
    const year = event.target.value; // Assuming year is a string like "2024"

    // Assuming 'range' is a JSON string that needs to be parsed
    let parsedRange = JSON.parse(this.state.range);

    // Concatenate the year with the rest of the date, including the dash
    parsedRange.$gte =
      year + parsedRange.$gte.substring(parsedRange.$gte.indexOf("-"));
    parsedRange.$lte =
      year + parsedRange.$lte.substring(parsedRange.$lte.indexOf("-"));

    this.presenter.onChangeDate(parsedRange);
    this.setState({ selectedYear: event.target.value });
  };

  countTransactionTypesFromMonth = (transactions, month, year) => {
    let totalGuest = 0;
    let totalTransaction = 0;

    transactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.tran_date);
      if (
        transactionDate.getMonth() === month &&
        transactionDate.getFullYear() === year
      ) {
        totalGuest += Number(transaction.cust_count || "0");
        totalTransaction += 1;
      }
    });

    return { totalGuest, totalTransaction };
  };

  getChartData() {
    const { objects, selectedStoreCode, selectedMonth, selectedYear } =
      this.state;

    // Filter data based on the selected store, month, and year
    const filteredSales = objects
      .filter((obj) => obj.status === "C")
      .filter((sale) => {
        const saleDate = new Date(sale.tran_date);
        const saleYear = saleDate.getFullYear();
        const saleMonth = saleDate.toLocaleString("default", { month: "long" });
        const saleStoreCode = sale.store_code.replace("ANGELSPIZZA_", "");

        const yearMatches = selectedYear
          ? saleYear === parseInt(selectedYear)
          : true;
        const monthMatches = selectedMonth
          ? saleMonth === this.state.selectedMonthName
          : true;
        const storeMatches = selectedStoreCode
          ? saleStoreCode === selectedStoreCode
          : true;

        return yearMatches && monthMatches && storeMatches;
      });

    const discountGross = filteredSales;

    const currentDate = new Date();
    const monthsToShow = 12; // Number of months you want to show including the current month
    const labels = [];
    const salesData = [];
    const discountData = [];

    for (let i = monthsToShow - 1; i >= 0; i--) {
      // Calculate the month and year, adjusting for when the iteration goes past the current month
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      const month = date.getMonth();
      const year = date.getFullYear();

      const monthTransactions = discountGross.filter((transaction) => {
        const transactionDate = new Date(transaction.tran_date);
        return (
          transactionDate.getMonth() === month &&
          transactionDate.getFullYear() === year
        );
      });

      if (monthTransactions.length > 0) {
        let monthSales = 0;
        let monthDiscounts = 0;
        let gross = 0;

        monthTransactions.forEach((transaction) => {
          const total = Number(transaction.total || 0);
          const discountAmount = Number(transaction.discount_amount || 0);
          const totalTax = Number(transaction.total_tax || 0);

          const grossSales = total;

          gross += grossSales;
          monthDiscounts += discountAmount;
        });

        salesData.push(gross);

        discountData.push(monthDiscounts);

        // Format it as 'Month Year'
        labels.push(
          date.toLocaleString("default", { month: "long", year: "numeric" })
        );
      }
    }

    return {
      labels,
      datasets: [
        {
          label: "Gross Sales",
          data: salesData,
          borderColor: "#1b74bb",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          yAxisID: "y", // Use the primary axis for Gross Sales
        },
        {
          label: "Discount Amount",
          data: discountData,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgba(255, 205, 86, 0.5)",
          yAxisID: "y1", // Use the secondary axis for Discount Amount
        },
      ],
    };
  }

  getChartOptions() {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Discount Value vs. Gross Sales",
        },
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
          beginAtZero: true,
          title: {
            display: true,
            text: "Gross Sales",
          },
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          beginAtZero: true,
          title: {
            display: true,
            text: "Discount Amount",
          },
          // Align the grid to the left axis
          grid: {
            drawOnChartArea: false,
          },
        },
        x: {
          title: {
            display: true,
            text: "Month",
          },
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { objects, selectedStoreCode, selectedYear } = this.state;

    // Create an object to store aggregated data for each month
    const aggregatedData = {};

    // Filter and aggregate data based on the selected store and year
    objects
      .filter((obj) => obj.status === "C")
      .forEach((sale) => {
        const saleDate = new Date(sale.createdAt);
        const saleYear = saleDate.getFullYear();
        const saleMonth = saleDate.toLocaleString("default", { month: "long" });
        const saleStoreCode = sale.store_code.replace("ANGELSPIZZA_", "");

        const yearMatches = selectedYear
          ? saleYear === parseInt(selectedYear)
          : true;
        const storeMatches = selectedStoreCode
          ? saleStoreCode === selectedStoreCode
          : true;

        if (yearMatches && storeMatches) {
          // Create a unique key for each month
          const monthKey = `${saleMonth} ${saleYear}`;

          // Calculate gross sales and discount amount for each sale
          const total = Number(sale.total || 0);
          const discountAmount = Number(sale.discount_amount || 0);
          const totalTax = Number(sale.total_tax || 0);
          const grossSales = total + discountAmount - totalTax;

          // Initialize or update aggregated data for the month
          if (!aggregatedData[monthKey]) {
            aggregatedData[monthKey] = {
              Brand: "Angels Pizza", // Replace with your actual brand data
              Store: saleStoreCode,
              Month: saleMonth,
              Year: saleYear,
              "Gross Sales": grossSales,
              "Discount Amount": discountAmount,
            };
          } else {
            aggregatedData[monthKey]["Gross Sales"] += grossSales;
            aggregatedData[monthKey]["Discount Amount"] += discountAmount;
          }
        }
      });

    // Convert the aggregated data to an array of rows
    const rows = Object.values(aggregatedData);

    // Define CSV headers
    const headers = [
      "Brand",
      "Store",
      "Month",
      "Year",
      "Gross Sales",
      "Discount Amount",
    ];
    let csvContent = [headers.join(",")];

    // Loop over each row and format the data
    rows.forEach((row) => {
      const rowData = [
        row.Brand,
        row.Store,
        row.Month,
        row.Year,
        row["Gross Sales"],
        row["Discount Amount"],
      ];
      csvContent.push(rowData.join(","));
    });

    // Convert the rows to a single string
    csvContent = csvContent.join("\n");

    // Create a Blob and trigger the download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `DiscountGross_${selectedYear}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // In your render method, add a button to trigger this function
  // <button onClick={this.exportToCSV}>Export to CSV</button>

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const years = [
      ...new Set(
        this.state.objects.map((obj) => new Date(obj.createdAt).getFullYear())
      ),
    ];
    const chartId = "chart-container17";

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "#83c14a" }}>Discount Value</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Gross Sales</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>

        <div className="row mb-2">
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              name="selectedMonth"
              // value={this.state.selectedMonth}
              onChange={this.handleDropdownChange}
            >
              <option
                defaultValue
                disabled={
                  this.state.selectedMonth || this.state.selectedMonthName
                }
              >
                Select Month
              </option>
              <option value="AllMonths">All Months</option>
              {this.months().map((month, index) => (
                <option key={index} value={JSON.stringify(month.createdAt)}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={this.handleYearChange}
              value={this.state.selectedYear}
            >
              {/* <option
                defaultValue
                disabled={this.state.selectedYear && this.state.selectedYear}
              >
                Select Year
              </option>
              <option value="2023">2023</option>
              <option value="2024">2024</option> */}
              <option value="" disabled>
                Select Year
              </option>
              {Array.from(
                { length: 3 },
                (_, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 && (
          <div ref={this.chartRef1} id="chart-container17">
            <div className="card">
              <Bar data={chartData} options={chartOptions} />;
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Month</th>
                      <th className="ms-5">Gross Sales</th>{" "}
                      <th className="ms-5">Discount Amount</th>{" "}
                    </tr>
                  </thead>

                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label}</td>
                        <td>{chartData.datasets[0].data[index].toFixed(2)}</td>
                        <td>{chartData.datasets[1].data[index].toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {this.state.storess &&
          this.presenter.progress &&
          this.state.selectedMonthName &&
          this.state.selectedYear && (
            <div className="text-center mt-5">
              <Progress />
              <h6>Processing... Please wait.</h6>
            </div>
          )}

        {!this.state.storess && !this.state.selectedYear && (
          <div div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}

        {/* {!this.presenter.progress &&
          this.state.objects?.length === 0 &&
          this.state.storess &&
          this.state.selectedMonthName &&
          this.state.selectedYear && (
            <div className="text-center mt-5">
              <h6>There's no data for the selected criteria.</h6>
            </div>
          )} */}
        {!this.presenter.progress && this.state.objects?.length === 0 && (
          <div className="text-center mt-5">
            <h6>There's no data for the selected criteria.</h6>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(DiscountGrossPage);
