import React, { useState, useEffect, useMemo, useRef } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { InputSelect } from "nq-component";
import Export from "./components/Export";
import "./index.css";
import { Progress } from "nq-component";
import printJS from "print-js";

function MonthlySalesChartPage({
  objects,
  onChangeDate,
  onChangeStore,
  dateField,
  store,
  storess,
  progress,
}) {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [optionss, setOptionss] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterBox, setFilterBox] = useState(false);
  const chartRef = useRef(null);
  const [selectedMonthName, setSelectedMonthName] = useState("");

  const [range, setRange] = useState("");

  // console.log("ANG TAGAL:", objects);

  useEffect(() => {
    processData();
  }, [objects]);

  const processData = () => {
    const data = objects;

    const storeCodes = data?.reduce((acc, item) => {
      const code = item.store_code.split("ANGELSPIZZA_")[1]; // Remove prefix
      if (code && !acc.includes(code)) {
        acc.push(code);
      }
      return acc;
    }, []);

    setOptionss(storeCodes?.map((code) => ({ value: code, label: code })));
  };

  const onChangeType = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Pre-process ung objects para sa date parsing
  // Modify the useMemo that processes the objects
  const parsedObjects = useMemo(() => {
    return objects
      ?.filter((obj) => obj.status === "C")
      ?.map((obj) => {
        const parts = obj.store_code.split("ANGELSPIZZA_");
        const codePart = parts.length > 1 ? parts[1] : ""; // Get the part after 'ANGELSPIZZA_'
        return {
          ...obj,
          parsedMonth: new Date(obj.tran_date).toLocaleString("default", {
            month: "long",
          }),
          parsedYear: new Date(obj.tran_date).getFullYear().toString(),
          storeCodePart: codePart, // Add this property
        };
      });
  }, [objects]);

  // Update filtered data when selections change
  useEffect(() => {
    const filtered = parsedObjects?.filter((obj) => {
      const matchesMonth = selectedMonthName
        ? obj.parsedMonth ===
          (selectedMonth === "AllMonths" ? selectedMonth : selectedMonthName)
        : true;
      const matchesYear = selectedYear ? obj.parsedYear === selectedYear : true;
      const matchesOption = selectedOption
        ? obj.storeCodePart === selectedOption // Compare using the new storeCodePart
        : true;
      return matchesMonth && matchesYear && matchesOption;
    });
    setFilteredData(filtered);
  }, [
    parsedObjects,
    selectedMonth,
    selectedYear,
    selectedOption,
    selectedMonthName,
  ]);

  const calculateGrowthPercentage = (salesData) => {
    let previousMonthSales = null;
    let growthPercentages = {};

    Object.entries(salesData).forEach(([monthYear, data], index) => {
      if (previousMonthSales !== null && previousMonthSales !== 0) {
        const growthPercentage =
          ((data.sales - previousMonthSales) / previousMonthSales) * 100;
        growthPercentages[monthYear] = growthPercentage;
      }
      previousMonthSales = data.sales;
    });

    return growthPercentages;
  };

  // Compute sales data using the filtered data
  const salesData = useMemo(() => {
    const result = filteredData.reduce((acc, obj) => {
      const monthYear = `${obj.parsedMonth} ${obj.parsedYear}`;
      const gross = parseFloat(obj.total);
      //  +
      // parseFloat(obj.discount_amount) +
      // parseFloat(obj.total_tax);
      const sales = gross - parseFloat(obj.total_tax);

      if (!acc[monthYear]) {
        acc[monthYear] = { gross: 0, sales: 0 };
      }
      acc[monthYear].gross += gross;

      acc[monthYear].sales += sales;

      return acc;
    }, {});

    if (selectedMonth && !selectedYear) {
      return Object.keys(result)
        .sort((a, b) => new Date(a) - new Date(b))
        .reduce((obj, key) => {
          obj[key] = result[key];
          return obj;
        }, {});
    }
    return result;
  }, [filteredData, selectedMonth, selectedYear]);

  const growthPercentages = useMemo(
    () => calculateGrowthPercentage(salesData),
    [salesData]
  );

  // Preparing chart data
  const chartLabels = Object.keys(salesData);
  const chartSalesData = Object.values(salesData).map((data) => data.sales);
  const chartGrowthData = chartLabels.map(
    (label) => growthPercentages[label] || 0
  );

  const data = {
    labels: chartLabels.reverse(),
    datasets: [
      {
        type: "bar", // Bar chart for sales
        label: "Monthly Sales",
        data: chartSalesData,
        backgroundColor: ["#006096"],
        borderColor: ["#006096"],
        borderWidth: 1,
      },
      {
        type: "line", // Line chart for growth percentage
        label: "Growth Percentage",
        data: chartGrowthData,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        beginAtZero: true,
      },
    },

    plugins: {
      datalabels: {
        color: "#FFF",
        anchor: "center",
        align: "center",
        font: {
          size: 20,
        },
        formatter: (value, context) => {
          return value.toLocaleString();
        },
      },
    },
  };

  const handleMonthChange = (event) => {
    const monthNames = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };
    let monthName;
    setSelectedMonth(event.target.value);
    console.log("Months", event.target.value);
    // console.log("b", event.target.value)

    if (event.target.value === "AllMonths") {
      onChangeDate("AllMonths");
    } else {
      setRange(event.target.value);
      const cleanedValue = event.target.value.replace(/-/g, "");
      const parsedValue = JSON.parse(cleanedValue);
      console.log("month", parsedValue);
      onChangeDate(parsedValue);
      const monthi = JSON.parse(event.target.value).$gte.split("-");
      monthName = Object.keys(monthNames).find(
        (key) => monthNames[key] === monthi[1]
      );
    }

    setSelectedMonthName(monthName);
  };

  const handleYearChange = (event) => {
    const year = event.target.value; // Assuming year is a string like "2024"

    // Assuming 'range' is a JSON string that needs to be parsed
    let parsedRange = JSON.parse(range);

    // Concatenate the year with the rest of the date, including the dash
    parsedRange.$gte =
      year + parsedRange.$gte.substring(parsedRange.$gte.indexOf("-"));
    parsedRange.$lte =
      year + parsedRange.$lte.substring(parsedRange.$lte.indexOf("-"));

    setSelectedYear(event.target.value);
    onChangeDate(parsedRange);
  };

  const months = useMemo(() => {
    // const year = selectedYear || new Date().getFullYear();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthsFormatted = monthNames.map((name, index) => {
      const monthNumber = (index + 1).toString().padStart(2, "0");
      const firstDayOfMonth = `${selectedYear}-${monthNumber}-01T00:00:00.000Z`;

      // Calculate the last day of the month
      const lastDayOfMonthDate = new Date(selectedYear, index + 1, 0); // The 0 here gets the last day of the previous month, i.e., the month we're interested in
      // const lastDayOfMonth = `${lastDayOfMonthDate.getFullYear()}-${(lastDayOfMonthDate.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonthDate.getDate().toString().padStart(2, '0')}T23:59:59.999Z`;
      const lastDayOfMonth = `${selectedYear}-${(
        lastDayOfMonthDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${lastDayOfMonthDate
        .getDate()
        .toString()
        .padStart(2, "0")}T23:59:59.999Z`;

      return {
        name, // The display name of the month
        createdAt: {
          $gte: firstDayOfMonth,
          $lte: lastDayOfMonth,
        },
      };
    });

    return monthsFormatted;
  }, [objects, , onChangeStore]);

  const years = useMemo(() => {
    return Array.from(
      new Set(objects?.map((obj) => new Date(obj.createdAt).getFullYear()))
    );
  }, [objects]);

  function convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }

  const exportToCSV = () => {
    const csvData = [];
    Object.entries(salesData).forEach(([monthYear, data]) => {
      csvData.push({
        brand: "Angels Pizza",
        store: storess,
        monthYear,
        sales: data.sales,
      });
    });

    const csvString = convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = "monthly-sales-data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const formatSales = (sales) => {
    return sales.toLocaleString("en-US", {
      style: "currency",
      currency: "PHP",
    });
  };

  const printChart = () => {
    setTimeout(() => {
      printJS({
        printable: "chart-container",
        type: "html",
        header: `<div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
                   <img src="/figaro.png" alt="Figaro" style="height: 50px;"/>
                   <h4 style="margin: 0 auto;">FIGARO COFFEE GROUP</h4>
                   <img src="/angels.png" alt="Angel's Pizza" style="height: 50px;"/>
                 </div>`,
        css: "./index.css",
        scanStyles: false,
        style: ".print-only-content { display: block; }",
      });
    }, 500);
  };

  return (
    <>
      {/* <NavBar /> */}
      <div className="d-flex justify-content-between align-items-center mb-1">
        <h2 className="fw-bold text-capitalize">
          <span style={{ color: "darkcolor" }}>Monthly</span>
          <span style={{ color: "#83c14a" }}> Sales Chart</span>
        </h2>
        <Export exportCsv={exportToCSV} exportPdf={printChart} />
      </div>
      {/* Chart Component */}
      <div className="row mb-2">
        <div className="col-sm-3">
          <select
            className="form-select"
            onChange={(e) => onChangeStore(e.target.value)} // Correctly passing the value
          >
            <option defaultValue disabled={storess && storess}>
              Select Store
            </option>
            <option value="AllStore">All Store</option>
            {store.map((store, index) => (
              <option key={index} value={store.name}>
                {store.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-2">
          <select
            id="month-select"
            className="form-select"
            // value={selectedMonth}
            onChange={handleMonthChange}
          >
            <option defaultValue disabled={selectedMonth || selectedMonthName}>
              Select Month
            </option>
            <option value="AllMonths">All Months</option>
            {months.map((month, index) => (
              <option key={index} value={JSON.stringify(month.createdAt)}>
                {month.name}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="col-sm-2">
          <select
            id="year-select"
            className="form-select"
            value={selectedYear}
            onChange={handleYearChange}
          >
            
            <option defaultValue disabled={selectedYear && selectedYear}>
              Select Year
            </option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          
          </select>
        </div> */}
        <div className="col-sm-2">
          <select
            id="year-select"
            className="form-select"
            value={selectedYear}
            onChange={handleYearChange}
          >
            <option value="" disabled>
              Select Year
            </option>
            {Array.from(
              { length: 3 },
              (_, i) => new Date().getFullYear() - i
            ).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      {!storess && !selectedYear && (
        <div div className="text-center align-items-center mt-4">
          <img
            src="./choosefirst.png"
            style={{ height: "200px", width: "200px" }}
          />
          <h4>To proceed, please select a store first</h4>
        </div>
      )}

      {objects?.length === 0 &&
        storess &&
        selectedMonthName &&
        selectedYear && (
          <div div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        )}

      {!progress &&
        objects?.length > 0 &&
        storess &&
        selectedMonthName &&
        selectedYear && (
          <div ref={chartRef} id="chart-container">
            <div className="card">
              <Bar data={data} options={options} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className="d-flex justify-content-between align-items-center print-only-content">
                <h3>Brand: Angels Pizza</h3>
                <h4 className="mx-auto"></h4>
                <h3>Store: {storess}</h3>
              </div>
              <div className="print-only" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Month</th>
                      <th className="ms-5">Sales</th>
                      <th className="ms-5">Grown Percentage</th>
                    </tr>{" "}
                  </thead>
                  <tbody>
                    {data.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label}</td>
                        <td>{formatSales(data.datasets[0].data[index])}</td>
                        <td>{formatSales(data.datasets[1].data[index])}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

      {/* {!progress &&
        objects?.length === 0 &&
        storess &&
        selectedMonthName &&
        selectedYear && (
          <div className="text-center mt-5">
            <h6>There's no data for the selected criteria.</h6>
          </div>
        )} */}
      {/* {!progress && objects?.length === 0 && (
        <div className="text-center mt-5">
          <h6>There's no data for the selected criteria.</h6>
        </div>
      )} */}
    </>
  );
}

export default MonthlySalesChartPage;
