"use strict";

var _interopRequireDefault = require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/mweeb/Desktop/codings/nq-component/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js"));
function getPropertyByPath(source, path) {
  var keys = path.split('.');
  var current = source;
  var _iterator = (0, _createForOfIteratorHelper2["default"])(keys),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var p = _step.value;
      if (current[p] === undefined) {
        return undefined;
      }
      current = current[p];
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return current;
}
var _default = getPropertyByPath;
exports["default"] = _default;