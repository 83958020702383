import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import TotalGuestAvgSpendPresenter from "./TotalGuestAvgSpendPresenter";
import BaseListPage from "../../../base/BaseListPage";
import withRouter from "../../../withRouter";
import { findObjectUseCase } from "../../../usecases/object";
import Export from "../../../components/Export";
import printChart from "../../../PrintChart";
import { Progress } from "nq-component";

class TotalGuestAvgSpendPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TotalGuestAvgSpendPresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      selectedStoreCode: "",
      selectedMonth: "",
      selectedYear: "",
      storess: "",
      store: [],
      range: "",
      selectedMonth: "",
      selectedMonthName: "",
    };
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleDropdownChange(event) {
    // const { name, value } = event.target;
    // this.setState({ [name]: value });
    const monthNames = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };
    let monthName;
    this.setState({ selectedMonth: event.target.value });
    // console.log("b", event.target.value)

    if (event.target.value === "AllMonths") {
      this.presenter.onChangeDate("AllMonths");
    } else {
      this.setState({ range: event.target.value });
      this.presenter.onChangeDate(JSON.parse(event.target.value));
      const monthi = JSON.parse(event.target.value).$gte.split("-");
      monthName = Object.keys(monthNames).find(
        (key) => monthNames[key] === monthi[1]
      );
    }

    this.setState({ selectedMonthName: monthName });
  }

  getMonthOptions() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.map((month) => (
      <option key={month} value={month}>
        {month}
      </option>
    ));
  }

  months() {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthsFormatted = monthNames.map((name, index) => {
      const monthNumber = (index + 1).toString().padStart(2, "0");
      const firstDayOfMonth = `${this.state.selectedYear}-${monthNumber}-01T00:00:00.000Z`;

      // Calculate the last day of the month
      const lastDayOfMonthDate = new Date(
        this.state.selectedYear,
        index + 1,
        0
      ); // The 0 here gets the last day of the previous month, i.e., the month we're interested in
      // const lastDayOfMonth = `${lastDayOfMonthDate.getFullYear()}-${(lastDayOfMonthDate.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonthDate.getDate().toString().padStart(2, '0')}T23:59:59.999Z`;
      const lastDayOfMonth = `${this.state.selectedYear}-${(
        lastDayOfMonthDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${lastDayOfMonthDate
        .getDate()
        .toString()
        .padStart(2, "0")}T23:59:59.999Z`;

      return {
        name, // The display name of the month
        createdAt: {
          $gte: firstDayOfMonth,
          $lte: lastDayOfMonth,
        },
      };
    });

    return monthsFormatted;
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  handleYearChange = (event) => {
    const year = event.target.value; // Assuming year is a string like "2024"

    // Assuming 'range' is a JSON string that needs to be parsed
    let parsedRange = JSON.parse(this.state.range);

    // Concatenate the year with the rest of the date, including the dash
    parsedRange.$gte =
      year + parsedRange.$gte.substring(parsedRange.$gte.indexOf("-"));
    parsedRange.$lte =
      year + parsedRange.$lte.substring(parsedRange.$lte.indexOf("-"));

    this.presenter.onChangeDate(parsedRange);
    this.setState({ selectedYear: event.target.value });
  };

  chartData = () => {
    const { objects, selectedStoreCode, selectedMonth, selectedYear } =
      this.state;

    // Filter data based on the selected store, month, and year
    const filteredSales = objects
      .filter((status) => status.status === "C")
      .filter((sale) => {
        const saleDate = new Date(sale.createdAt);
        const saleYear = saleDate.getFullYear();
        const saleMonth = saleDate.toLocaleString("default", { month: "long" });
        const saleStoreCode = sale.store_code.replace("ANGELSPIZZA_", "");

        const yearMatches = selectedYear
          ? saleYear === parseInt(selectedYear)
          : true;
        const monthMatches = selectedMonth
          ? saleMonth === this.state.selectedMonthName
          : true;
        const storeMatches = selectedStoreCode
          ? saleStoreCode === selectedStoreCode
          : true;

        return yearMatches && monthMatches && storeMatches;
      });

    const grossSales = filteredSales;
    // const grossSales = this.state.objects;
    // const previousGrossMonth = 10000;
    // Grouping sales by months
    const salesByMonth = {};
    const customerCountsByMonth = {};

    grossSales.forEach((sale) => {
      // Exclude transactions where 'cust_name' is "-"
      if (sale.cust_name !== "-") {
        const date = new Date(sale.createdAt);
        const year = date.getFullYear();
        const month = date.toLocaleString("default", { month: "long" });
        const monthYear = `${month} ${year}`;

        if (!salesByMonth[monthYear]) {
          salesByMonth[monthYear] = [];
          customerCountsByMonth[monthYear] = {};
        }
        salesByMonth[monthYear].push(sale);

        // Count transactions for each customer
        if (!customerCountsByMonth[monthYear][sale.cust_name]) {
          customerCountsByMonth[monthYear][sale.cust_name] = 0;
        }
        customerCountsByMonth[monthYear][sale.cust_name]++;
      }
    });

    // Calculating total gross sales per month
    // const monthlyTotals = Object.keys(salesByMonth).map((monthYear) => {
    //   const monthlySales = salesByMonth[monthYear];
    //   const monthlyTotal = monthlySales.reduce((total, sale) => {
    //     // Convert string values to numbers and calculate the gross sales
    //     const totalValue = parseFloat(sale.total) || 0;
    //     const discountValue = parseFloat(sale.discount_amount) || 0;
    //     const taxValue = parseFloat(sale.total_tax) || 0;
    //     const gross =  totalValue + discountValue + taxValue

    //     // Assuming the formula is total - discount_amount - total_tax
    //     return total + totalValue - discountValue - taxValue;
    //   }, 0);
    //   return monthlyTotal;
    // });

    // Calculate the gross sales for each month
    const monthlyTotals = Object.keys(salesByMonth).map((monthYear) => {
      const monthlySales = salesByMonth[monthYear];
      const monthlyTotal = monthlySales.reduce((total, sale) => {
        // Convert string values to numbers and calculate the gross sales
        const totalValue = parseFloat(sale.total) || 0;
        const discountValue = parseFloat(sale.discount_amount) || 0;
        const taxValue = parseFloat(sale.total_tax) || 0;

        // Calculate the gross sales
        const gross = totalValue + discountValue + taxValue;

        return total + gross;
      }, 0);
      return monthlyTotal;
    });

    // Calculate the customer counts for each month
    const monthlyCustomerCounts = Object.keys(customerCountsByMonth).map(
      (monthYear) => {
        return Object.values(customerCountsByMonth[monthYear]).reduce(
          (total, count) => total + count,
          0
        );
      }
    );
    // Calculate the average spend per guest for each month
    const monthlyAverages = monthlyTotals.map((monthlyTotal, index) => {
      const customerCount = monthlyCustomerCounts[index];
      return customerCount ? monthlyTotal / customerCount : 0;
    });

    console.log("Monthly Averages:", monthlyAverages);

    return {
      labels: Object.keys(salesByMonth),
      datasets: [
        {
          type: "line",
          label: "Avg Spend per Guest",
          data: monthlyAverages,
          borderColor: "#ffd043",
          borderWidth: 2,
          fill: false,
          yAxisID: "y-axis-2",
        },
        {
          type: "bar",
          label: "Guest Count",
          data: monthlyCustomerCounts,
          backgroundColor: "#006096",
          yAxisID: "y-axis-1",
        },
      ],
    };
  };

  chartOptions = () => {
    return {
      scales: {
        "y-axis-1": {
          type: "linear",
          display: true,
          position: "left",
        },
        "y-axis-2": {
          type: "linear",
          display: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += `${context.parsed.y.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}`;
              }
              return label;
            },
          },
        },
      },
    };
  };

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const {
      selectedStoreCode,
      selectedMonth,
      selectedYear,
      storess,
      range,
      selectedMonthName,
    } = this.state;
    const chartData = this.chartData(); // This should be the method that generates the data for your chart

    // Extract the total guest counts and avg spend per guest from your chartData
    const totalGuestCounts = chartData.datasets.find(
      (dataset) => dataset.label === "Guest Count"
    ).data;
    const avgSpendPerGuest = chartData.datasets.find(
      (dataset) => dataset.label === "Avg Spend per Guest"
    ).data;

    const dateRange = JSON.parse(range);

    const csvData = chartData.labels.map((label, index) => {
      return {
        Brand: "Angels Pizza", // Replace with your actual brand logic
        Store: storess || "All Stores",
        Year: `${selectedYear}${dateRange.$lte.split("T")[0]} ${selectedYear}${
          dateRange.$gte.split("T")[0]
        }`,
        TotalGuests: totalGuestCounts[index],
        AvgSpendPerGuest: avgSpendPerGuest[index],
      };
    });

    // Convert to CSV string
    const csvString = this.convertToCSV(csvData);

    // Create a Blob from the CSV String
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    // Create a link element, use it to download the Blob, and remove it
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "GuestsAndSpending.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  render() {
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const years = [
      ...new Set(
        this.state.objects.map((obj) => new Date(obj.createdAt).getFullYear())
      ),
    ];
    const chartId = "chart-container11";

    return (
      <>
        {/* <NavBar /> */}

        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "#83c14a" }}>Total Guest</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Avg Spend per Guest</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>
        <div className="row mb-2">
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              name="selectedMonth"
              // value={this.state.selectedMonth}
              onChange={this.handleDropdownChange}
            >
              <option
                defaultValue
                disabled={
                  this.state.selectedMonth || this.state.selectedMonthName
                }
              >
                Select Month
              </option>
              <option value="AllMonths">All Months</option>
              {this.months().map((month, index) => (
                <option key={index} value={JSON.stringify(month.createdAt)}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={this.handleYearChange}
              value={this.state.selectedYear}
            >
              {/* <option
                defaultValue
                disabled={this.state.selectedYear && this.state.selectedYear}
              >
                Select Year
              </option>
              <option value="2023">2023</option>
              <option value="2024">2024</option> */}
              <option value="" disabled>
                Select Year
              </option>
              {Array.from(
                { length: 3 },
                (_, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 && (
          <div ref={this.chartRef1} id="chart-container11">
            <div className="card">
              <Bar data={this.chartData()} options={this.chartOptions()} />
            </div>

            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Month</th>
                      <th className="ms-5">Avg Spend</th>{" "}
                      <th className="ms-5">Guest Count</th>{" "}
                    </tr>
                  </thead>

                  <tbody>
                    {this.chartData().labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label} ------ </td>
                        <td>
                          {this.chartData().datasets[0].data[index].toFixed(2)}{" "}
                          ------{" "}
                        </td>
                        <td>{this.chartData().datasets[1].data[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {this.state.storess &&
          this.presenter.progress &&
          this.state.selectedMonthName &&
          this.state.selectedYear && (
            <div className="text-center mt-5">
              <Progress />
              <h6>Processing... Please wait.</h6>
            </div>
          )}

        {!this.state.storess && !this.state.selectedYear && (
          <div div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}

        {/* {
          !this.presenter.progress && this.state.objects?.length === 0 && this.state.storess && this.state.selectedMonthName && this.state.selectedYear &&
          <div className="text-center mt-5">
            <h6>There's no data for the selected criteria.</h6>
          </div>
        } */}
        {!this.presenter.progress && this.state.objects?.length === 0 && (
          <div className="text-center mt-5">
            <h6>There's no data for the selected criteria.</h6>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(TotalGuestAvgSpendPage);
