"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function toDisplayCase(str) {
  if (typeof str !== 'string') {
    return "";
  }
  // First, insert a space before all capital letters (for camelCase)
  str = str.replace(/([A-Z])/g, ' $1');
  // Replace underscores with spaces (for snake_case)
  str = str.replace(/_/g, ' ');
  // Split the string into words
  var words = str.split(' ');
  // Capitalize the first letter of each word
  return words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}
var _default = toDisplayCase;
exports["default"] = _default;