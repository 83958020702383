import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../../base/BaseListPage";
import { findObjectUseCase } from "../../../../usecases/object";
import withRouter from "../../../../withRouter";
import GrossTotalTransacPresenter from "./GrossTotalTransacPresenter";
import Export from "../../../../components/Export";
import printChart from "../../../../PrintChart";
import { Progress } from "nq-component";

class GrossTotalTransacPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new GrossTotalTransacPresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      selectedStoreCode: "",
      selectedMonth: "",
      selectedYear: "",
      store: [],
      storess: "",
      range: "",
      selectedMonth: "",
      selectedMonthName: "",
    };
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    this.setState({ store });
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleDropdownChange(event) {
    const monthNames = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };
    let monthName;
    this.setState({ selectedMonth: event.target.value });

    if (event.target.value === "AllMonths") {
      this.presenter.onChangeDate("AllMonths");
    } else {
      this.setState({ range: event.target.value });
      this.presenter.onChangeDate(JSON.parse(event.target.value));
      const monthi = JSON.parse(event.target.value).$gte.split("-");
      monthName = Object.keys(monthNames).find(
        (key) => monthNames[key] === monthi[1]
      );
    }

    this.setState({ selectedMonthName: monthName });
  }

  getMonthOptions() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.map((month) => (
      <option key={month} value={month}>
        {month}
      </option>
    ));
  }

  months() {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthsFormatted = monthNames.map((name, index) => {
      const monthNumber = (index + 1).toString().padStart(2, "0");
      const firstDayOfMonth = `${this.state.selectedYear}-${monthNumber}-01T00:00:00.000Z`;

      // Calculate the last day of the month
      const lastDayOfMonthDate = new Date(
        this.state.selectedYear,
        index + 1,
        0
      ); // The 0 here gets the last day of the previous month, i.e., the month we're interested in
      // const lastDayOfMonth = `${lastDayOfMonthDate.getFullYear()}-${(lastDayOfMonthDate.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonthDate.getDate().toString().padStart(2, '0')}T23:59:59.999Z`;
      const lastDayOfMonth = `${this.state.selectedYear}-${(
        lastDayOfMonthDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${lastDayOfMonthDate
        .getDate()
        .toString()
        .padStart(2, "0")}T23:59:59.999Z`;

      return {
        name, // The display name of the month
        createdAt: {
          $gte: firstDayOfMonth,
          $lte: lastDayOfMonth,
        },
      };
    });

    return monthsFormatted;
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  handleYearChange = (event) => {
    const year = event.target.value; // Assuming year is a string like "2024"

    // Assuming 'range' is a JSON string that needs to be parsed
    let parsedRange = JSON.parse(this.state.range);

    // Concatenate the year with the rest of the date, including the dash
    parsedRange.$gte =
      year + parsedRange.$gte.substring(parsedRange.$gte.indexOf("-"));
    parsedRange.$lte =
      year + parsedRange.$lte.substring(parsedRange.$lte.indexOf("-"));

    this.presenter.onChangeDate(parsedRange);
    this.setState({ selectedYear: event.target.value });
  };

  chartData = () => {
    const { objects, selectedStoreCode, selectedMonth, selectedYear } =
      this.state;

    // Filter data based on the selected store, month, and year
    const filteredSales = objects
      .filter((status) => status.status === "C")
      .filter((sale) => {
        const saleDate = new Date(sale.tran_date);
        const saleYear = saleDate.getFullYear();
        const saleMonth = saleDate.toLocaleString("default", { month: "long" });
        const saleStoreCode = sale.store_code.replace("ANGELSPIZZA_", "");

        const yearMatches = selectedYear
          ? saleYear === parseInt(selectedYear)
          : true;
        const monthMatches = selectedMonth
          ? saleMonth === this.state.selectedMonthName
          : true;
        const storeMatches = selectedStoreCode
          ? saleStoreCode === selectedStoreCode
          : true;

        return yearMatches && monthMatches && storeMatches;
      });

    const grossSales = filteredSales;
    const previousGrossMonth = 10000;
    // Grouping sales by months
    const salesByMonth = {};
    const transactionCountsByMonth = {};
    grossSales.forEach((sale) => {
      const date = new Date(sale.createdAt);
      const year = date.getFullYear();
      const month = date.toLocaleString("default", { month: "long" });
      const monthYear = `${month} ${year}`;

      if (!salesByMonth[monthYear]) {
        salesByMonth[monthYear] = [];
      }
      salesByMonth[monthYear].push(sale);

      if (!transactionCountsByMonth[monthYear]) {
        transactionCountsByMonth[monthYear] = 0;
      }
      transactionCountsByMonth[monthYear]++;
    });

    // Calculating total gross sales per month
    const monthlyTotals = Object.keys(salesByMonth).map((monthYear) => {
      const monthlySales = salesByMonth[monthYear];
      const monthlyTotal = monthlySales.reduce((total, sale) => {
        // Convert string values to numbers and calculate the gross sales
        const totalValue = parseFloat(sale.total) || 0;
        const discountValue = parseFloat(sale.discount_amount) || 0;
        const taxValue = parseFloat(sale.total_tax) || 0;

        // Assuming the formula is total - discount_amount - total_tax
        return total + totalValue - discountValue - taxValue;
      }, 0);
      return monthlyTotal;
    });

    const monthlyTransactionCounts = Object.keys(transactionCountsByMonth).map(
      (monthYear) => transactionCountsByMonth[monthYear]
    );

    return {
      labels: Object.keys(salesByMonth),
      datasets: [
        {
          type: "line",
          label: "Growth Sales",
          data: monthlyTotals,
          borderColor: "#ffd043",
          borderWidth: 2,
          fill: false,
          yAxisID: "y-axis-2",
        },
        {
          type: "bar",
          label: "Total Transactions",
          data: monthlyTransactionCounts,
          backgroundColor: "#006096",
          yAxisID: "y-axis-1",
        },
      ],
    };
  };

  chartOptions = () => {
    return {
      scales: {
        "y-axis-1": {
          type: "linear",
          display: true,
          position: "left",
        },
        "y-axis-2": {
          type: "linear",
          display: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += `${context.parsed.y.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}`;
              }
              return label;
            },
          },
        },
      },
    };
  };

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }

  exportToCSV = () => {
    const chartData = this.chartData();
    const { selectedStoreCode, storess, selectedMonth, selectedYear } =
      this.state;

    // Construct CSV data array
    const csvData = chartData.labels.map((label, index) => {
      return {
        Brand: "Angels Pizza", // Replace this with actual brand logic
        Store: storess || "All Stores",
        Month:
          `${selectedYear}-${
            JSON.parse(selectedMonth).$lte.split("T")[0]
          } ${selectedYear}-${JSON.parse(selectedMonth).$gte.split("T")[0]}` ||
          "All Years",
        TotalTransactions: chartData.datasets.find(
          (dataset) => dataset.label === "Total Transactions"
        ).data[index],
        GrowthSales: chartData.datasets.find(
          (dataset) => dataset.label === "Growth Sales"
        ).data[index],
      };
    });

    const csvString = this.convertToCSV(csvData);

    // Download the CSV file
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "GrossSales-TotalTransactions.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  exportChartAsPDFs = () => {
    setTimeout(() => {
      window.print();
    }, 500);
  };

  render() {
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const years = [
      ...new Set(
        this.state.objects.map((obj) => new Date(obj.createdAt).getFullYear())
      ),
    ];
    const chartId = "chart-container6";
    const formatSales = (sales) => {
      return sales.toLocaleString("en-US", {
        style: "currency",
        currency: "PHP",
      });
    };

    return (
      <>
        {/* <NavBar /> */}

        <div className="d-flex justify-content-between align-items-center mb-1">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "#83c14a" }}>Gross Sales</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Total Transaction</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>

        <div className="row mb-2">
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              name="selectedMonth"
              // value={this.state.selectedMonth}
              onChange={this.handleDropdownChange}
            >
              <option
                defaultValue
                disabled={
                  this.state.selectedMonth || this.state.selectedMonthName
                }
              >
                Select Month
              </option>
              <option value="AllMonths">All Months</option>
              {this.months().map((month, index) => (
                <option key={index} value={JSON.stringify(month.createdAt)}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-sm-3">
            <select
              className="form-select"
              onChange={this.handleYearChange}
              value={this.state.selectedYear}
            >
              <option
                defaultValue
                disabled={this.state.selectedYear && this.state.selectedYear}
              >
                Select Year
              </option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div> */}
          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={this.handleYearChange}
              value={this.state.selectedYear}
            >
              <option value="" disabled>
                Select Year
              </option>
              {Array.from(
                { length: 3 },
                (_, i) => new Date().getFullYear() - i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 && (
          <div ref={this.chartRef1} id="chart-container6">
            <div className="card">
              <Bar data={this.chartData()} options={this.chartOptions()} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className="d-flex justify-content-between align-items-center print-only-content">
                <h3>Brand: Angels Pizza</h3>
                <h4 className="mx-auto"></h4>
                <h3>Store: {this.state.selectedStoreCode}</h3>
              </div>
              <div className="print-only" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Month</th>
                      <th className="ms-5">Sales</th>
                      <th className="ms-5">Grown Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.chartData().labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label}</td>
                        <td>
                          {formatSales(
                            this.chartData().datasets[0].data[index]
                          )}
                        </td>
                        <td>
                          {formatSales(
                            this.chartData().datasets[1].data[index]
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {this.state.storess &&
          this.presenter.progress &&
          this.state.selectedMonthName &&
          this.state.selectedYear && (
            <div className="text-center mt-5">
              <Progress />
              <h6>Processing... Please wait.</h6>
            </div>
          )}

        {!this.state.storess && !this.state.selectedYear && (
          <div div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}

        {/* {
          !this.presenter.progress && this.state.objects?.length === 0 && this.state.storess && this.state.selectedMonthName && this.state.selectedYear &&
          <div className="text-center mt-5">
            <h6>There's no data for the selected criteria.</h6>
          </div>
        } */}
        {!this.presenter.progress && this.state.objects?.length === 0 && (
          <div className="text-center mt-5">
            <h6>There's no data for the selected criteria.</h6>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(GrossTotalTransacPage);
